import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

//import Layout from "../components/layout"
import Seo from "../components/seo"

const AboutPage = () => (
  <>
    <Seo title="About" />
    <h1>About</h1>
    <StaticImage
      className="profile-image"
      src="../images/mat.jpg"
      width={128}
      alt=""
    />
    <p>
      I was born in the region of The Hague, got my upbringing in Maastricht
      where I went to school just across the border in Belgium. After secondary
      school I went to Eindhoven to study applied physics but found it to be too
      theoretical. So I worked several years in different practical technical
      fields with different companies. During that time I increasingly noticed
      that programming has been a welcome way for me to spend my free time.
      Whether it's wrestling with Linux configurations, setting up server
      processes or writing scripts and programs. Programming & figuring out how
      certain devices work is a repeating and enjoyable theme in my life. That
      is why I've decided to make the career switch to IT. The unregulated
      nature and low threshold access to self-study materials makes IT a lot of
      fun to me.
    </p>
    <p>
      My girlfriend and I love the outdoors, Lindy-hop dancing and Rock&Roll
      dancing. I'm interested in practical philosophy, privacy, free speech,
      finance and politics. And I can definitely enjoy an intellectually
      stimulating sci-fi movie like{" "}
      <a href="https://www.imdb.com/title/tt0133093/">The Matrix</a>.
    </p>
    <p>
      Any project that increases the power and awareness of individuals in the
      world is a project I'd love to work on. To be more specific, I'd love to
      work for a team or project with a technological focus on privacy, free
      speech, deregulation or decentralization.
    </p>
  </>
)

export default AboutPage
